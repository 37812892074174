import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import { ApiServiceDelete } from "../services/api-service";
import { toast } from "react-toastify";
import FormView from "./FormView";
import Select from "./Select";
import { BUCKET_URL } from '../services/helper';
import Icon from "./Icon";

const ListView = ({ table, data, columns, showFilter, createRow, editRow, deleteRow, viewRow, title, download, pageSize = 10 }) => {

    const [pageCount, setPageCount] = useState(data ? Math.ceil(data.length / pageSize) : 0);
    const [paginatedData, setPaginatedData] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [showForm, setShowForm] = useState(false);
    const [columnsWD, setColumnsWD] = useState();
    const [action, setAction] = useState();
    const [selectedRow, setSelectedRow] = useState();
    const [listColumns, setListColumns] = useState();
    const [formColumns, setFormColumns] = useState();
    const [fcolumn, setFcolumn] = useState();
    const [foperator, setFoperator] = useState();
    const [fvalue, setFvalue] = useState();
    const [selColumn, setSelColumn] = useState();

    useEffect(() => {
        setListColumns(_.filter(columns, ['element', 'list']));
        setFormColumns(_.filter(columns, ['element', 'form']));

        setPaginatedData(_(data).slice(0).take(pageSize).value());
    }, [data, columns, pageSize]);

    const pages = _.range(1, pageCount + 1);

    const onPagination = (pageNumber) => {
        setCurrentPage(pageNumber);
        const startIndex = (pageNumber - 1) * pageSize;
        setPaginatedData(_(data).slice(startIndex).take(pageSize).value());
    }

    const onRowCreate = () => {
        const temp = formColumns.map((data) => {
            let tempData = { ...data } // Copy object
            tempData.data = '' // Set new field
            return tempData
        });
        setAction('save');
        setColumnsWD(temp);
        setShowForm(true);
    }

    const onRowEdit = (eachRow) => {
        const temp = formColumns.map((data) => {
            let tempData = { ...data } // Copy object
            tempData.data = eachRow[tempData.name] // Set new field
            return tempData
        });
        setAction('update');
        setColumnsWD(temp);
        setShowForm(true);
        setSelectedRow(eachRow.id);
    }

    const onRowDelete = (rowId) => {
        ApiServiceDelete(rowId, table).then((resp) => {
            removeRow(rowId);
            toast.success("Record Deleted");
        }).catch((error) => {
            console.log(error);
            toast.error(error.message);
        });
    }

    const removeRow = (rowId) => {
        setPaginatedData(_.filter(paginatedData, function (o) { return o.id !== rowId }));
    }

    const formClosed = (obj) => {
        if (obj.action === 'save') {
            paginatedData.push(obj.payload);
        } else if (obj.action === 'update') {
            setPaginatedData(_(paginatedData).keyBy('id').set(obj.payload.id, obj.payload).values().value());
        }
        setShowForm(false);
    }

    const onRefresh = () => { }

    const renderSwitch = (eachRow, column, index) => {
        const tname = (typeof (eachRow[column.name]) === "object" && eachRow[column.name] !== null) ? eachRow[column.name].label : eachRow[column.name];
        switch (column.type) {
            case 'Url':
                return (
                    <td key={index}>
                        <a href={tname} target="_blank" rel="noreferrer" >{tname}</a>
                    </td>
                )
            case 'Boolean':
                return (
                    <td key={index}>
                        <input type="checkbox" defaultChecked={tname} />
                    </td>)
            default:
                return <td key={index}>{tname}</td>
        }
    }

    const operator = [
        {
            id: 1,
            label: "is",
            value: "="
        },
        {
            id: 2,
            label: "is not",
            value: "!="
        },
        {
            id: 3,
            label: "starts with",
            value: "sw"
        },
        {
            id: 4,
            label: "ends with",
            value: "ew"
        },
    ];

    const handleChange = (event, property) => {
        let temp = selColumn;
        if (temp.type === "Options" || temp.type === "Reference") {
            temp.data = {
                label: event.target[event.target.selectedIndex].text, value: event.target.value
            };
        }
        else if (temp.type === "Boolean") {
            temp.data = !temp.data;
        }
        else {
            temp.data = event.target.value;
        }
        setSelColumn(temp);
        setFvalue(event.target.value);
    }

    const onSearch = () => {
        let tData;
        if (selColumn.type === "Options" || selColumn.type === "Reference") {
            tData = _.filter(data, [fcolumn + '.value', fvalue]);
            setPaginatedData(tData);
        }
        else {
            tData = _.filter(data, [fcolumn, fvalue]);
            setPaginatedData(tData);
        }
        setPageCount(tData ? Math.ceil(tData.length / pageSize) : 0);
    }

    const onAll = () => {
        // setFcolumn('');
        // setFoperator('');
        // setFvalue('');
        setPaginatedData(_(data).slice(0).take(pageSize).value());
        setPageCount(data ? Math.ceil(data.length / pageSize) : 0);
    }

    const dynamicFilter = (name) => {
        console.log(name);
        setFcolumn(name);
        const temp = _.find(listColumns, { name: name });
        temp.data = { label: 'Please select', value: '0' };
        setSelColumn(temp);
    }

    function switchRender() {
        return (
            <div>
                {selColumn && (() => {
                    switch (selColumn['type']) {
                        case 'Reference':
                            return <Select eachRow={selColumn} handleChange={(e) => handleChange(e)} />;
                        case 'Options':
                            return <Select eachRow={selColumn} handleChange={(e) => handleChange(e)} />;
                        case 'Boolean':
                            return <input type="checkbox" className="checkbox" onChange={(e) => handleChange(e)} />;
                        case 'Integer':
                            return <input type="number" className="form-control" onChange={(e) => handleChange(e)} />;
                        default:
                            return <input type="text" className="form-control" onChange={(e) => handleChange(e)} />;
                    }
                })()}
            </div>
        )
    };

    const downloadFile = (url) => {
        window.open(BUCKET_URL + url, '_blank');
    }

    const openLink = (url) => {
        window.open(url, '_blank');
    }

    return (
        <div >
            <div className="default-heading">{title}</div>
            {showFilter &&
                <div className="row">
                    <div className="col-3">
                        <select className="form-select" aria-label="Default select example" onChange={(e) => dynamicFilter(e.target.value)}>
                            <option>Choose field</option>
                            {listColumns && listColumns.map((eachOption) => {
                                return <option key={eachOption.id} value={eachOption.name}>{eachOption.label}</option>
                            })}
                        </select>
                    </div>
                    <div className="col-3">
                        <select className="form-select col-2" aria-label="Default select example" value={foperator} onChange={(e) => setFoperator(e.target.value)}>
                            <option>Choose operator</option>
                            {operator.map((eachOption) => {
                                return <option key={eachOption.id} value={eachOption.value}>{eachOption.label}</option>
                            })}
                        </select>
                    </div>
                    <div className="col-3">
                        {switchRender()}
                        {/* <input type="text" className="form-control" value={fvalue} onChange={(e) => setFvalue(e.target.value)} /> */}
                    </div>
                    <div className="col-3">
                        <button className="btn btn-outline-primary btn-sm mr-1" onClick={() => onSearch()}>Search</button>
                        <button className="btn btn-outline-primary btn-sm mr-1" onClick={() => onAll()}>All</button>
                    </div>
                </div>
            }
            <table className="table">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        {listColumns && listColumns.map((column, index) => {
                            return (
                                <th key={index} scope="col">{column.label}</th>
                            )
                        })}
                        {createRow === "true" ? (<td>
                            <button className="btn btn-success btn-sm" onClick={() => onRowCreate()}><i className="bi bi-vector-pen"></i></button>
                            <button className="btn btn-success btn-sm" onClick={() => onRefresh()}><i className="bi bi-arrow-repeat"></i></button>
                        </td>) : <td></td>}
                    </tr>
                </thead>
                <tbody>
                    {paginatedData && paginatedData.map((eachRow, index) => {
                        return (
                            <tr key={index}>
                                <td key={index}>{index + 1}</td>
                                {listColumns.map((column, index) => {
                                    return (
                                        // <td key={index}>{(typeof (eachRow[column.name]) === "object" && eachRow[column.name] !== null) ? eachRow[column.name].label : eachRow[column.name]}</td>
                                        renderSwitch(eachRow, column, index)
                                    )
                                })}
                                {(editRow === "true" || viewRow === "true" || deleteRow === "true" || download === "true") ? (
                                    <td>
                                        {editRow === "true" ? (<button className="btn btn-outline-primary btn-sm mr-1" onClick={() => onRowEdit(eachRow)}><i className="bi bi-pencil-square"></i></button>) : null}
                                        {viewRow === "true" ? (<Link className="btn btn-outline-primary btn-sm" to={`/admin/form/${table}/${eachRow.id}`}>View</Link>) : null}
                                        {deleteRow === "true" ? (<button className="btn btn-outline-danger btn-sm" onClick={() => onRowDelete(eachRow.id)}><i className="bi bi-trash"></i></button>) : null}
                                        {download === 'true' && eachRow.url && eachRow.url.length > 5 ? (
                                            eachRow.url.startsWith("http") ?
                                                <button className='btn btn-outline-primary btn-sm' onClick={() => openLink(eachRow.url)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" className="bi bi-download" >
                                                        <path d="M10.0002 5H8.2002C7.08009 5 6.51962 5 6.0918 5.21799C5.71547 5.40973 5.40973 5.71547 5.21799 6.0918C5 6.51962 5 7.08009 5 8.2002V15.8002C5 16.9203 5 17.4801 5.21799 17.9079C5.40973 18.2842 5.71547 18.5905 6.0918 18.7822C6.5192 19 7.07899 19 8.19691 19H15.8031C16.921 19 17.48 19 17.9074 18.7822C18.2837 18.5905 18.5905 18.2839 18.7822 17.9076C19 17.4802 19 16.921 19 15.8031V14M20 9V4M20 4H15M20 4L13 11" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                </button>
                                                :
                                                <button className='btn btn-outline-primary btn-sm' onClick={() => downloadFile(eachRow.url)}>
                                                    {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="currentColor" className="bi bi-download">
                                                        <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                                        <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                                                    </svg> */}
                                                    <Icon icon="download" color="info" size="sm"></Icon>
                                                </button>
                                        ) : null}
                                    </td>) : null}
                            </tr>
                        )
                    })}
                </tbody>
            </table>

            {pageCount > 1 ? (<nav className='d-flex justify-content-center'>
                <ul className='pagination'>
                    {pages.map((page) => {
                        return (
                            <li key={page} className={page === currentPage ? "page-item active" : "page-item"}>
                                <div onClick={() => onPagination(page)} className="page-link">
                                    {page}
                                </div>
                            </li>
                        )
                    })}
                </ul>
            </nav>) : null}
            {showForm ? (<FormView title={title} table={table} columns={columnsWD} id={selectedRow} action={action} formClosed={(obj) => { return formClosed(obj); }} />) : null}
        </div>
    );
}

export default ListView;