import Icon from "./Icon";

const SocialIcons = () => {
    return (
        <ul className="social-icons">
            <li><a href="https://www.facebook.com/people/Gulbarga-University-Kalburagi/100088642871100/" target="_blank" rel="noreferrer"><Icon icon="facebook" color="light" size="md"></Icon></a></li>
            <li><a href="#"><Icon icon="instagram" color="light" size="md"></Icon></a></li>
            <li><a href="https://twitter.com/OfficialGUK106" target="_blank" rel="noreferrer"><Icon icon="twitter" color="light" size="md"></Icon></a></li>
            <li><a href="https://www.youtube.com/@gulbargauniversitykalabura787/streams" target="_blank" rel="noreferrer"><Icon icon="youtube" color="light" size="md"></Icon></a></li>
        </ul>
    );
}

export default SocialIcons;